
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Navbar from './components/Navigation';
import Application from './components/Application';
import Weldings from './components/Weldings';
import WeldingMachines from './components/WeldingMachines';
import WeldingGalleryAndForm from './components/WeldingsAndForm';
import WeldingSection from './components/OrbitalWelding';
import MainPage from './components/MainPage';
import HamburgerMenu from './components/HamburgerMenu';

import { Provider } from 'react-redux';
import store from './redux/store';
import BlogPostTop3 from './components/Blogs/BlogPostTop3';
import OrbitalisHegesztesTortelnelme from './components/Blogs/BlogPostHistory';
import EzertLeszelEgyreErtekesebbMintHegeszto from './components/Blogs/BlogPostValuable';
import AMagyarOrbitalisHegesztoAzOrbitalChamp from './components/Blogs/BlogPostHunWeld';
import MiertNemVesziElAHegesztokMunkajatAzOrbitalisHegesztes from './components/Blogs/BlogPostWhyNot';
import Shop from './components/Shop';
import ShopItemSmartWeldingSystem from './components/ShopItems/ShopItemSmartWeldingSystem';
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom.min';
import ShopItemWelderheadDIN50 from './components/ShopItems/ShopItemWelderheadDIN50';
import ShopItemWelderheadDIN100 from './components/ShopItems/ShopItemWelderheadDIN100';
import ShopItemSmartBox from './components/ShopItems/ShopItemSmartbox';
import ContactUsPage from './components/SeparateContactPage';


function App() {

  console.log(window.innerWidth + "x" + window.innerHeight)

  if(window.innerWidth>762){
    return (
      <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="/shop">
          <Navbar />
            <Shop />
          </Route>
          <Route path="/Top3OkaHogyMiertHasznaljOrbitalisHegesztot">
            <div>
              <Navbar />
              <BlogPostTop3 />
            </div>
          </Route> 
          <Route path="/OrbitalisHegesztesTortelnelme">
            <div>
              <Navbar />
              <OrbitalisHegesztesTortelnelme />
            </div>
          </Route> 
          <Route path="/EzertLeszelEgyreErtekesebbMintHegeszto">
            <div>
              <Navbar />
              <EzertLeszelEgyreErtekesebbMintHegeszto />
            </div>
          </Route> 
          <Route path="/AMagyarOrbitalisHegesztoAzOrbitalChamp">
            <div>
              <Navbar />
              <AMagyarOrbitalisHegesztoAzOrbitalChamp />
            </div>
          </Route> 
          <Route path="/MiertNemVesziElAHegesztokMunkajatAzOrbitalisHegesztes">
            <div>
              <Navbar />
              <MiertNemVesziElAHegesztokMunkajatAzOrbitalisHegesztes />
            </div>
          </Route> 
          <Route path="/ShopItemSmartWeldingSystem">
            <Navbar />
            <ShopItemSmartWeldingSystem />
          </Route>
          <Route path="/ShopWelderHeadDIN50">
            <Navbar />
            <ShopItemWelderheadDIN50 />
          </Route>
          <Route path="/ShopWelderHeadDIN100">
            <Navbar />
            <ShopItemWelderheadDIN100 />
          </Route>
          <Route path="/ShopSmartbox">
            <Navbar />
            <ShopItemSmartBox/>
          </Route>
<Route path="/ContactUsPage">
  <div>
    <Navbar />
    <ContactUsPage />
  </div>
</Route>
          <Route path="/">
            <div>
              <Navbar />
              <MainPage />
            </div>
          </Route>

         
        </Switch>
      </BrowserRouter>
    </Provider>
      
    );
    /*return (
    <Provider store={store}>
    <Router>
      <div>
      <Navbar/>
      <Home/>
      <About/>
      <WeldingMachines/>
      <WeldingGalleryAndForm/>
      <WeldingSection/>
      <Contact/>
      </div>
    </Router>
    </Provider>
  );*/
  }
  else
  {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="/shop">
          <HamburgerMenu />
            <Shop />
          </Route>
          <Route path="/Top3OkaHogyMiertHasznaljOrbitalisHegesztot">
            <div>
            <HamburgerMenu />
              <BlogPostTop3 />
            </div>
          </Route> 
          <Route path="/OrbitalisHegesztesTortelnelme">
            <div>
            <HamburgerMenu />
              <OrbitalisHegesztesTortelnelme />
            </div>
          </Route> 
          <Route path="/EzertLeszelEgyreErtekesebbMintHegeszto">
            <div>
            <HamburgerMenu />
              <EzertLeszelEgyreErtekesebbMintHegeszto />
            </div>
          </Route> 
          <Route path="/AMagyarOrbitalisHegesztoAzOrbitalChamp">
            <div>
            <HamburgerMenu />
              <AMagyarOrbitalisHegesztoAzOrbitalChamp />
            </div>
          </Route> 
          <Route path="/MiertNemVesziElAHegesztokMunkajatAzOrbitalisHegesztes">
            <div>
            <HamburgerMenu />
              <MiertNemVesziElAHegesztokMunkajatAzOrbitalisHegesztes />
            </div>
          </Route> 
          <Route path="/ShopItemSmartWeldingSystem">
            <HamburgerMenu />
            <ShopItemSmartWeldingSystem />
          </Route>
          <Route path="/ShopWelderHeadDIN50">
            <HamburgerMenu />
            <ShopItemWelderheadDIN50 />
          </Route>
          <Route path="/ShopWelderHeadDIN100">  
            <HamburgerMenu />
            <ShopItemWelderheadDIN100 />
          </Route>
          <Route path="/ShopSmartbox">
            <HamburgerMenu />
            <ShopItemSmartBox/>
          </Route>
         
<Route path="/ContactUsPage">
  <div>
    <HamburgerMenu />
    <ContactUsPage />
  </div>
</Route>
          <Route path="/">
            <div>
            <HamburgerMenu />
            <MainPage />
            </div>
          </Route>
         
        </Switch>
      </BrowserRouter>
    </Provider>
     );
  }
}

export default App;
