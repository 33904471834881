import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import translations from '../strings/Strings';
import kep1 from '../img/new photo/1.png';
import kep6 from '../img/new photo/6.png';
import kep3 from '../img/new photo/3.png';
import '../Designe/Shop.css';
import { useHistory } from 'react-router-dom'; // Import useHistory
import { setLanguage } from '../redux/actions';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHomeLgAlt } from "@fortawesome/free-solid-svg-icons";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'; // Új sor hozzáadva
import { ToastContainer, toast } from 'react-toastify';

function Shop() {
  const dispatch = useDispatch(); // Get dispatch function
  const language = useSelector((state) => state.language);
  const [strings, setStrings] = useState(translations.hun);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 762);
  const [buttonText, setButtonText] = useState('');
  const history = useHistory(); // Initialize useHistory hook
const [isLoading, setIsLoading] = useState(false); // Loading state
   const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [feedback, setFeedback] = useState({ message: '', type: '' });

 const [isPopupOpen, setIsPopupOpen] = useState(false);

const dialogRef = useRef(null);

  const handleEmailClick = () => {
    if(isMobile)
    {
      const emailAddress = 'info@orbitalchamp.com';
      const emailSubject = 'Érdeklődni szeretnék!';
      const emailBody = 'Kedves OrbitalChamp kft!'; // Ide írd a szöveget
      const emailLink = `mailto:${emailAddress}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
      
      window.open(emailLink, '_blank');

    }
    else
    {
      if(isPopupOpen)
      {
        setIsPopupOpen(false); // Open the popup
        dialogRef.current.close();
      }
      else
      {
        setIsPopupOpen(true); // Open the popup
        dialogRef.current.showModal();
      }

    }
  };

  const handleMobileClick = () => {
    const phoneNumber = '+36305622299';
    const phoneLink = `tel:${phoneNumber}`;
    
    window.open(phoneLink, '_self'); // '_self' biztosítja, hogy ugyanabban az ablakban nyíljon meg
  };
  
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'hun'; // Default to Hungarian
    dispatch(setLanguage(savedLanguage)); // Set language in Redux
    setStrings(translations[savedLanguage]);
  }, [dispatch]);

  // Update strings when the language changes
  useEffect(() => {
    console.log('Language changed:', language);
    switch (language) {
      case 'en':
        setStrings(translations.en);
        break;
      case 'hun':
        setStrings(translations.hun);
        break;
      case 'de':
        setStrings(translations.de);
        break;
      default:
        break;
    }
  }, [language]);

  // Update button text based on screen size
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 762);
    };

    if (isMobile) {
      setButtonText('🔙'); // Use dynamic text from strings or fallback to Hungarian
    } else {
      setButtonText(strings.Shop_BackToMainPage); // Use dynamic text for desktop
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile, strings.Shop_BackToMainPage]);

  // Update button text for mobile on mount
  useEffect(() => {
    if (isMobile) {
      setButtonText('🔙'); // Use dynamic text from strings or fallback to Hungarian
    } else {
      setButtonText(strings.Shop_BackToMainPage); // Use dynamic text for desktop
    }
  }, [isMobile, strings.Shop_BackToMainPage]);

  // Handle product click
  const handleProductClick = (product) => {
    switch (product.id) {
      case 1:
        history.push("/ShopItemSmartWeldingSystem");
        console.log("pushed");
        break;
      case 2:
        history.push("/ShopWelderHeadDIN50");
        break;
      case 3:
        history.push("/ShopWelderHeadDIN100");
        break;
      case 4:
        history.push("/ShopSmartbox");
        break;
      default:
        break;
    }
  };

// Save language to local storage when it changes
useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'hun'; // Default to Hungarian
    dispatch(setLanguage(savedLanguage)); // Set language in Redux
    setStrings(translations[savedLanguage])
}, [dispatch]);


  const products = [
    {
      id: 1,
      title: strings.Shop_SmartWeldingSystemTitle,
      price: "6999 € + " + strings.VAT,
      image: kep6,
    },
    {
      id: 2,
      title: strings.Shop_WelderheadTitleDIN50,
      price: "4449 € + " + strings.VAT,
      image: kep1,
    },
    {
      id: 3,
      title: strings.Shop_WelderheadTitleDIN100,
      price: "4449 € + " + strings.VAT,
      image: kep1,
    },
    {
      id: 4,
      title: strings.Shop_SmartBoxTitle,
      price: "2499 € + " + strings.VAT,
      image: kep3,
    },
  ];


const handleSubmit = (e) => {
    e.preventDefault();
    // Itt a beküldés logikája
  setIsLoading(true); // Set loading state to true when the form is submitted
    // Adatok elküldése a szervernek
    axios
      .post('https://app-ppd7fma2sq-uc.a.run.app/sendmail', {
        to: 'info@orbitalchamp.com', // Ide írd be a célba küldendő email címet
        subject: 'Üzenet az Orbitalchamp.com-ról!',
        message: `
          Név: ${name}
          Email: ${email}
          Cégnév: ${company}
          Telefonszám: ${phoneNumber}
          Üzenet: ${message}
        `,
      })
      .then((response) => {
        console.log('Sikeres email küldés:', response.data.message);
        setIsPopupOpen(false);dialogRef.current.close();
        if(window.innerWidth>762){
          alert(strings.Shop_Item_Successful_Sending);
        }else{
          alert('Az üzenet sikeresen elküldve!')
        }
          setIsLoading(false); // Set loading to false when request is complete
      })
      .catch((error) => {
        setIsPopupOpen(false);dialogRef.current.close();
        
        alert(strings.Toast_Error_Email + error);
          setIsLoading(false); // Set loading to false when request is complete
      });
  };

  // Function to close the popup
  const handleClosePopup = () => {
    setIsPopupOpen(false); // Close the popup
        dialogRef.current.close();

  };



  if(!isMobile)
  {
    return (
      <div className="shop-container">
        
        <div className="BASZODJMEG">
          <a href="/" className="button-back">
            <p className='kibaszott-shop-button-text'>{buttonText} </p>
              <FontAwesomeIcon icon={faHomeLgAlt} style={{ fontSize: "50%" }} color="#960000" />
          </a>
          <h1 className="jakab">{strings.Shop_OurProducts}:</h1>
        
        <button className="contact-button" onClick={handleEmailClick}>
          <p className='kibaszott-shop-button-text'>{strings.Welcome_Button}</p>
          <FontAwesomeIcon icon={faMailBulk} color="#960000" style={{ fontSize: "200%" }} />
          
        </button>
        {/* The ContactFormPopup is rendered as a dialog */}
        <dialog ref={dialogRef}>
          <div className="popup-content">
            <h2>{strings.Contact_title}</h2>
            <form className='shopForm'>
            <input
              type="text"
              id="name"
              name="name"
              placeholder={strings.Contact_name}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />

            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <input
              type="text"
              id="company"
              name="company"
              placeholder={strings.Contact_Company_name}
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />

            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder={strings.Contact_Phone}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />

            <textarea
              id="message"
              name="message"
              rows="6"
              placeholder={strings.Conatct_message_desc}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />

              <button type="submit" className="close-button" onClick={handleSubmit}>
                {isLoading ? (
                    <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: '20px', marginRight: '8px' }} />
                  ) :
                strings.Conatct_Send}
              </button>
            </form>
            <button className="close-button" onClick={handleClosePopup}>{strings.Shop_Cancel}</button>
          </div>
        </dialog>
          
        </div>
        <div className="products">
          {products.map((product) => (
            <div 
              className="product-card" 
              key={product.id} 
              onClick={() => handleProductClick(product)} 
            >
              <img src={product.image} alt={product.title} className="product-image" />
              <div className="product-info">
                <p>{product.title}</p>
                <p className="product-price">{strings.Shop_Price}: {product.price}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    ); 

  }
  else
  {
    return (
      <div className="shop-container">
        <div className="BASZODJMEG-MOBILON-IS">
          <a href="/" className="button-back-baszodjmeg">
              <FontAwesomeIcon icon={faHomeLgAlt} className='contact-icon'color="#960000" />
          </a>
          <h1 className="jakab-mobile">{strings.Shop_OurProducts}:</h1>
        
        <button className="contact-button-baszodjmeg" onClick={handleMobileClick}>
          <FontAwesomeIcon icon={faPhoneAlt} color="#960000" className='contact-icon' />
        </button>
      


        </div>
        <div className="products">
          {products.map((product) => (
            <div 
              className="product-card" 
              key={product.id} 
              onClick={() => handleProductClick(product)} 
            >
              <img src={product.image} alt={product.title} className="product-image" />
              <div className="product-info">
                <p>{product.title}</p>
                <p className="product-price">{strings.Shop_Price}: {product.price}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    ); 

  }
}

export default Shop;
