import Home from './/Home';
import About from './About';
import Contact from './Contact';
import Navbar from './Navigation';
import Application from './Application';
import Weldings from './Weldings';
import WeldingMachines from './WeldingMachines';
import WeldingGalleryAndForm from './WeldingsAndForm';
import WeldingSection from './OrbitalWelding';
import '../Designe/MainPage.css'
import React, { useState, useEffect } from 'react';

function MainPage() {

return (

    <div className='MainContainer'>
        <Home />
        <About />
        <WeldingMachines />
        <WeldingGalleryAndForm />
        <WeldingSection />
        <Contact />
    </div>


);

    
}
export default MainPage;
