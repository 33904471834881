import React, { useState, useEffect } from 'react';
import { slide as Menu } from 'react-burger-menu';
import '../Designe/Navbar.css';
import Logo from '../img/Logo.svg';
import { Link , useLocation} from 'react-router-dom/cjs/react-router-dom.min';
import scrollToSection from './ScrollToSection';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../redux/actions';
import ENG from '../img/united-kingdom.png';
import HUN from '../img/hungary.png';
import DEU from '../img/germany.png';
import translations from '../strings/Strings'


const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [strings,setStrings] = useState(translations.hun)
  
  const [flagSrc, setFlagSrc] =useState(HUN);

  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const location = useLocation(); 
  const history = useHistory();
  const handleMenuStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleMenuItemClick = () => {
    setMenuOpen(false);
  };

  const EN = () => {
    dispatch(setLanguage('en'));
    setStrings(translations.en);
    localStorage.setItem('language', 'en'); // Save language in localStorage
};

const HU = () => {
    dispatch(setLanguage('hun'));
    setStrings(translations.hun);
    localStorage.setItem('language', 'hun'); // Save language in localStorage
};

const DE = () => {
    dispatch(setLanguage('de'));
    setStrings(translations.de);
    localStorage.setItem('language', 'de'); // Save language in localStorage
};
  useEffect(() => {

    switch (language) {
      case 'en':
        setStrings(translations.en);
        break;
      case 'hun':
        setStrings(translations.hun);
        break;
      case 'de':
        setStrings(translations.de);
        break;
      default:
        break;
    }

    

    // Frissítjük a windowWidth állapotot, amikor az ablak mérete megváltozik
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Töröljük az eseménykezelőt a komponens megszűnésekor
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [language]);


useEffect(() => {
    // Check localStorage for saved language
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
        dispatch(setLanguage(savedLanguage)); // Set the language in Redux state
        setStrings(translations[savedLanguage]); // Set the corresponding translations
        if (savedLanguage === 'en') {
            setFlagSrc(ENG);
        } else if (savedLanguage === 'hun') {
            setFlagSrc(HUN);
        } else if (savedLanguage === 'de') {
            setFlagSrc(DEU);
        }
    }

    // Frissítjük a windowWidth állapotot, amikor az ablak mérete megváltozik
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Töröljük az eseménykezelőt a komponens megszűnésekor
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);


  const handleNavClick = (sectionId) => {
    // Only scroll if we are on the home page
    if (location.pathname === '/') {
      scrollToSection(sectionId);
    }
    else
    {
      navigateAndScroll(sectionId);
    }
  };
  const navigateAndScroll = (sectionId) => {
    history.push('/');
    setTimeout(() => {
      scrollToSection(sectionId);
    }, 100);
  };

  return (
    <nav className="navbar">
       <div className='menu-conatiner_'>

      <ul className="navbar-menu">
        <div className="navbar-logo">
          <img src={Logo} style={{height: window.innerHeight/8}} onClick={() => handleNavClick('home')} alt="OrbitalChamp" />
        </div>
          <Link to="/Orbitális hegesztő" onClick={() => handleNavClick('Orbitális hegesztő')} className="nav-link">
            <li className="navbar-item">
              <a href="#Orbitális hegesztő" className="navbar-link" style={{fontSize: windowWidth/70}}>
                {strings.Navbar_Product_title}
              </a>
            </li>
          </Link>
          <Link to="/AWI Hegesztőgépek" onClick={() => handleNavClick('AWI Hegesztőgépek')} className="nav-link">
            <li className="navbar-item">
              <a href="/AWI Hegesztőgépek" className="navbar-link" style={{fontSize: windowWidth/70}}>
              {strings.Navbar_Application_title}
              </a>
            </li>
          </Link>
          <Link to="/Csőhegesztés" onClick={() => handleNavClick('Csőhegesztés')}className="nav-link">
            <li className="navbar-item">
              <a href="/Csőhegesztés" className="navbar-link" style={{fontSize: windowWidth/70}}>
                {strings.Navbar_Weldings_title}
              </a>
            </li>
          </Link>
          <Link to="/Orbitális Hegesztés" onClick={() => handleNavClick('Orbitális Hegesztés')}className="nav-link">
            <li className="navbar-item">
              <a href="/Orbitális Hegesztés" className="navbar-link" style={{fontSize: windowWidth/70}}>
                {strings.Navbar_OrbitalWelding_title}
              </a>
            </li>
          </Link>
          <Link to="/contact" onClick={() => handleNavClick('contact')}className="nav-link">
            <li className="navbar-item">
              <a href="/contact" className="navbar-link" style={{fontSize: windowWidth/70}}>
                {strings.Navbar_Contact_title}
              </a>
            </li>
          </Link>
          <Link to="/Top3OkaHogyMiertHasznaljOrbitalisHegesztot" className = "nav-link">
            <li className="navbar-item">
              <a className="navbar-link" href="/Top3OkaHogyMiertHasznaljOrbitalisHegesztot" style={{fontSize: windowWidth/70}}>Blog</a>
            </li>
          </Link>
          <Link to="/shop" className="nav-link">
            <li className="navbar-item">
              <a href="/shop" className="navbar-link2" style={{fontSize: windowWidth/40}}>
                {strings.Webshop}
              </a>
            </li>
          </Link>
            <li className="navbar-item">
              <div className="lang-selector-container">
                <div className="flag-row">
                  <img src={flagSrc} alt="English" className="flag-image" />
                </div>
                <div className="selector-row">
                  <select
                    className="custom-dropdown-lang"
                    onChange={(event) => {
                      const value = event.target.value;
                      if (value === "EN") 
                      {
                        EN();
                        setFlagSrc(ENG);
                      }
                      if (value === "HU")
                      {
                        HU();
                        setFlagSrc(HUN);
                      } 
                      if (value === "DE")
                      {
                        DE();
                        setFlagSrc(DEU);
                      }
                    }}
                  >
                  <option value="" disabled selected>{strings.Change}</option> {/* Alapértelmezett szöveg */}
                    <option value="EN">English</option>
                    <option value="HU">Magyar</option>
                    <option value="DE">Deutsch</option>
                  </select>
                </div>
              </div>
            </li>

        </ul>
       
    {/* Hamburger menü 
    <Menu onStateChange={handleMenuStateChange} isOpen={isMenuOpen}>
      

      <a className="menu-item"  href="/shop">Webshop</a>
      <a className="menu-item"  href="/Top3OkaHogyMiertHasznaljOrbitalisHegesztot">Blog</a>
    </Menu>
    */}
    </div>
    </nav>
    
    
  ); 

  return (
    
    <div className='menu-conatiner'>
    {/* Hamburger menü */}
    <Menu onStateChange={handleMenuStateChange} isOpen={isMenuOpen}>
      {/* Itt vannak a menüpontok */}
      <a className="menu-item" onClick={() => (handleMenuItemClick('about'))}>
        {strings.Navbar_Product_title}
      </a>
      <a className="menu-item" onClick={() => (handleMenuItemClick('AWI Hegesztőgépek'))}>
      {strings.Navbar_Application_title}
      </a>
      <a className="menu-item" onClick={() => (handleMenuItemClick('Csőhegesztés'))}>
      {strings.Navbar_Weldings_title}
      </a>
      <a className="menu-item" onClick={() => (handleMenuItemClick('Orbitális Hegesztés'))}>
      {strings.Navbar_OrbitalWelding_title}
      </a>
      <a className="menu-item"  onClick={() => (handleMenuItemClick('contact'))}>
        {strings.Navbar_Contact_title}
      </a>
    </Menu>
  </div>
  );
};

export default Navbar;
