import React, { useState, useEffect } from 'react';

import '../Designe/About.css'

import kez from '../img/new photo/9.png'
import middle from '../img/new photo/1.png'
import tobb from '../img/new photo/2.png'
import iv from '../img/new photo/7.png'
import elektronika from '../img/new photo/3.png'
import csat from '../img/new photo/4.png'

import { useSelector, useDispatch } from 'react-redux';
import translations from '../strings/Strings'

function About() {


  const language = useSelector((state) => state.language);
  const [strings,setStrings] = useState(translations.hun)


  const points = [
    {
      Title : 'OrbitalChamp',
      Description: strings.About_Head_desc,
      Image: window.innerWidth>762 ? middle : middle ,
      Size: window.innerWidth>762 ? window.innerWidth/4.5 : window.innerHeight/3
    },
    {
      Title : strings.About_High_Quality_Weldings_title,
      Description: strings.About_High_Quality_Weldings_desc ,
      Image: iv,
      Size: window.innerWidth>762 ? window.innerWidth/4 : window.innerHeight/2.5
    },

    {
      Title : strings.About_Electronic_title,
      Description: strings.About_Electronic_desc,
      Image: elektronika,
      Size: window.innerWidth>762 ? window.innerWidth/5.5 : window.innerHeight/2.5
    },
    {
      Title : strings.About_UI_title,
      Description: strings.About_UI_desc,
      Image: kez,
      Size: window.innerWidth>762 ? window.innerWidth/5.5 : window.innerHeight/2.5
    },
    {
      Title : strings.About_Your_Welder_title,
      Description: strings.About_Your_Welder_desc,
      Image: csat,
      Size: window.innerWidth>762 ? window.innerWidth/5.5 : window.innerHeight/2.5
    },
    {
      Title : strings.About_All_Pipe_title,
      Description: strings.About_All_Pipe_desc,
      Image: tobb,
      Size: window.innerWidth>762 ? window.innerWidth/5.5 : window.innerHeight/2.5
    },
    

  ]

  const font_div = 80;
  const [Title, setTitle] = useState(points[0].Title);
  const [Description, setDescription] = useState(points[0].Description);
  const [Image, setImage] = useState(points[0].Image);
  const [Index, setIndex] = useState(0);
  const [Size, setSize] = useState(points[0].Size)



  const handleDecrementClick = () => {

    const newIndex = Index - 1;

    if(newIndex >= 0){
      setIndex(newIndex);
      setTitle(points[newIndex].Title);
      setDescription(points[newIndex].Description);
      setImage(points[newIndex].Image);
      setSize(points[newIndex].Size);
    }
  };

  const handleIncrementClick = () => {
    const newIndex = Index + 1;
    if(newIndex < points.length){
      setIndex(newIndex);
      setTitle(points[newIndex].Title);
      setDescription(points[newIndex].Description);
      setImage(points[newIndex].Image);
      setSize(points[newIndex].Size);
      console.log('csako')
    }
  };

  const handleDirectClick = (number) => {
    setIndex(number);
    setTitle(points[number].Title);
    setDescription(points[number].Description);
    setImage(points[number].Image);
    setSize(points[number].Size);
  };

  useEffect(() => {
    // Itt lehet kezelni a nyelv állapotváltozásait
    switch(language) {
      case 'en':
        setStrings(translations.en);
        setIndex(0);
        setTitle(translations.en.About_Head_title);
        setDescription(translations.en.About_Head_desc);
        setImage(points[0].Image);
        setSize(points[0].Size);
        break;
      case 'de':
        setStrings(translations.de);
        setIndex(0);
        setTitle(translations.de.About_Head_title);
        setDescription(translations.de.About_Head_desc);
        setImage(points[0].Image);
        setSize(points[0].Size);
        break;
      case 'hun':
        setStrings(translations.hun);
        setIndex(0);
        setTitle(translations.hun.About_Head_title);
        setDescription(translations.hun.About_Head_desc);
        setImage(points[0].Image);
        setSize(points[0].Size);
        break;
      default:
        console.error(`Unsupported language: ${language}`);
        break;
    }
    


  }, [language]);
  



  if(window.innerWidth>762){
  return (
    <div className="about-container" id='Orbitális hegesztő'>
      <div className="about-column about-column-a">
        <div className='A-column-a' >
          <div className='A-row-a' onClick={()=>handleDirectClick(5)} >
            <img src={tobb} style={{width: window.innerWidth/5.5,}} alt='DIN20 - DIN100 csőhegesztés' />
            <a style={{fontSize: window.innerWidth/font_div}}>{points[5].Title}</a>
          </div>
          <div className='A-row-a' onClick={()=>handleDirectClick(4)} >
          <img src={csat} style={{width: window.innerWidth/5.5}} alt='AWI hegesztés'/>
            <a style={{fontSize: window.innerWidth/font_div}}>{points[4].Title}</a>
          </div>
        </div>
        <div className='A-column-a' >
        <div className='A-row-b' >
            <img src={iv} style={{width: window.innerWidth/5.5}} onClick={()=>handleDirectClick(1)} alt='Orbitális Hegesztés'/>
            <a style={{fontSize: window.innerWidth/font_div}}>{points[1].Title}</a>
          </div>
          <div className='A-row-b'onClick={()=>handleDirectClick(0)} >
            <img src={middle} style={{width:  window.innerWidth/4}} className="rotate-image"/>
          </div>
          <div className='A-row-b' >          
          </div>
        </div>
        <div className='A-column-a' >
        <div className='A-row-a' >
        <img src={kez} style={{width:  window.innerWidth/5.5}} onClick={()=>handleDirectClick(3)} alt='Csőhegesztés'/>
            <a style={{fontSize: window.innerWidth/font_div}}>{points[3].Title}</a>
          </div>
          <div className='A-row-a' >
            <img src={elektronika} style={{width:  window.innerWidth/5.5}} onClick={()=>handleDirectClick(2)} alt='Orbitalum - Axxair '/>
            <a style={{fontSize: window.innerWidth/font_div}}>{points[2].Title}</a>
          </div>
        </div>
      </div>
      <div className="about-column about-column-b">
        <div className='Title-container'>
          <h2 id="szoveg" className="about-column-b-title"  >{Title}</h2>
        </div>
      <div id="text" className='description'>
        <p style={{fontSize: window.innerWidth/80}}>{Description}</p>
        <img id="image" src={Image} style={{width: Size}}/>
        <div className='buttons' >
          <button onClick={handleDecrementClick} className="left-button" style={{fontSize: window.innerWidth/100}}  aria-label="Előző">{strings.About_Back}</button>
          <button onClick={handleIncrementClick} className="right-button" style={{fontSize: window.innerWidth/100}} aria-label="Tovább" >{strings.About_Next}</button>
        </div>
      </div>
      </div>
    </div>
  );
  }else{
    return (
      <div className="about-container-mobile" id="about">
        <div className="about-column-b-mobile">
          <h1
            id="szoveg"
            className="about-column-b-title-mobile"
            style={{ fontSize: 40 }}
          >
            {Title}
          </h1>
          <p className="description-mobile">{Description}</p>
          <img id="image" src={Image} className="about-image-mobile" />
          <div className="buttons-mobile">
            <button
              onClick={handleDecrementClick}
              className="left-button-mobile"
            >
              {strings.About_Back}
            </button>
            <button
              onClick={handleIncrementClick}
              className="right-button-mobile"
            >
              {strings.About_Next}
            </button>
          </div>
        </div>
      </div>
    );

  }
}

export default About;
