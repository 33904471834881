import React, { useEffect, useState } from 'react';
import '../Designe/Home.css'
//import Welcome from '../img/1-min.png'
import WelcomeImage from '../img/new photo/5.png'
import WelcomeVideo from '../img/video/WelcomeVideo.mp4'
import WelcomeVideo_mobile from '../img/video/WelcomeVideo_mobile.mp4';
import scrollToSection from './ScrollToSection';

import { useSelector, useDispatch } from 'react-redux';

import translations from '../strings/Strings'
//import { isMobile } from 'react-device-detect';



const Home =() => {

  const language = useSelector((state) => state.language);
  const [strings,setStrings] = useState(translations.hun)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 762);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 762);
    };

    
    // Itt lehet kezelni a nyelv állapotváltozásait
    console.log('Nyelv megváltozott:', language);
    switch (language) {
      case 'en':       
        setStrings(translations.en)
        break;  
      case 'hun':        
        setStrings(translations.hun)
        break;       
      case 'de':        
          setStrings(translations.de)
          break;         
      default:
        break;
    }
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [language]);

  return (
    <div className="home-page" id="home" style={{ height: '100vh', width: '100%' }}>
      {isMobile ? (
        <div className="home-page-container">



          <div className="title-overlay">
            <h2 className="Title">OrbitalChamp</h2>
          </div>

          <div className="button-overlay">
            <button 
              onClick={() => scrollToSection('contact')} 
              className="cta-button" 
              aria-label="Lépjen kapcsolatba velünk"
            >
              {strings.Welcome_Button}
            </button>
          </div>
            <video className="center-video" autoPlay loop muted poster={WelcomeImage} playsInline>
              <source src={WelcomeVideo_mobile} type="video/mp4" />
              <p style={{ color: 'red', textAlign: 'center', fontSize: '14px' }}>
                Your browser does not support the video tag.
              </p>
            </video>
        </div>

      ) : (
        <video className="center-video" autoPlay loop muted poster={WelcomeImage}>
          <source src={WelcomeVideo} type="video/mp4" />
          <p style={{ color: 'red', textAlign: 'center', fontSize: '14px' }}>
            Your browser does not support the video tag.
          </p>
        </video>
      )}
    </div>
  );
}

export default Home;
