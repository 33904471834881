import React, { useEffect, useState } from 'react';
import '../../Designe/Blogs/BlogPostHunWeld.css';
import hegesztobacsi from '../../img/footer/IMG_9394.jpg';
import iv from '../../img/en.jpg';
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../../redux/actions';

import translations from '../../strings/Strings'

function BlogPostHunWeld() {

  const language = useSelector((state) => state.language);
  const [strings,setStrings] = useState(translations.hun)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 762);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 762);
    };

    
    // Itt lehet kezelni a nyelv állapotváltozásait
    console.log('Nyelv megváltozott:', language);
    switch (language) {
      case 'en':       
        setStrings(translations.en)
        break;  
      case 'hun':        
        setStrings(translations.hun)
        break;       
      case 'de':        
          setStrings(translations.de)
          break;         
      default:
        break;
    }
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [language]);
  
// Save language to local storage when it changes
const dispatch = useDispatch();
useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'hun'; // Default to Hungarian
    dispatch(setLanguage(savedLanguage)); // Set language in Redux
    setStrings(translations[savedLanguage])
}, [dispatch]);




  var url = 'https://orbitalchamp.com/AMagyarOrbitalisHegesztoAzOrbitalChamp';
  var title = 'A magyar orbitális hegesztő: az OrbitalChamp';
  var description = 'Az ipari technológia folyamatos fejlődése számos innovációt hozott a hegesztési eljárások terén. Az egyik ilyen jelentős fejlesztés az orbitális hegesztés, amely különösen fontos szerepet tölt be a csővezetékek és csövek hegesztésében. Magyarország is büszkélkedhet egy ilyen innovációval: az OrbitalChamp nevű orbitális hegesztőberendezésse';
  var imageUrl = hegesztobacsi;
  
  useEffect(() => {
    // Set the document title
    document.title = title;

    // Update meta tags dynamically
    const metaTags = [
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
     { property: 'og:image', content: imageUrl },
      { property: 'og:url', content: url },
      { property: 'og:type', content: 'article' },
      { property: 'og:site_name', content: 'OrbitalChamp.com' }
    ];

    metaTags.forEach(tag => {
      let element = document.querySelector(`meta[property='${tag.property}']`);
      
      if (!element) {
        // If the meta tag doesn't exist, create it
        element = document.createElement('meta');
        element.setAttribute('property', tag.property);
        document.head.appendChild(element);
      }

      element.setAttribute('content', tag.content);
    });
  }, [title, description, imageUrl, url]);  
  


  if(isMobile==false){
  return (
    <div className="blog-container">
      <div className="menu">
        <ul>
          <li><a href="/shop" replace>{strings.Webshop}</a></li>
          <li><a href="/Top3OkaHogyMiertHasznaljOrbitalisHegesztot"                 >{strings.Blog_Top3_Title}       </a></li>
          <li><a href="/OrbitalisHegesztesTortelnelme"                              >{strings.Blog_History_Title}    </a></li>
          <li><a href="/EzertLeszelEgyreErtekesebbMintHegeszto"                     >{strings.Blog_Valuable_Title}   </a></li>
          <li><a className="a_active" href="/AMagyarOrbitalisHegesztoAzOrbitalChamp">{strings.Blog_Hungarian_Title}  </a></li>
          <li><a href="/MiertNemVesziElAHegesztokMunkajatAzOrbitalisHegesztes"      >{strings.Blog_NoJobsTaken_Title}</a></li>
        </ul>
      </div>

    <div className="blog-content">
      <h1 className="bela">{strings.Blog_Hungarian_Title}</h1>
      <img
        className="welding-image_hun"
        src={hegesztobacsi}
        alt="Orbitális hegesztő munka közben"
      />
      <p>{strings.Blog_Hungarian_Paragraph1}</p>

      <h2>{strings.Blog_Hungarian_WhatIs_Title}</h2>
      <p>{strings.Blog_Hungarian_WhatIs_Text}</p>

      <h2>{strings.Blog_Hungarian_Advantages_Title}</h2>
      <p>{strings.Blog_Hungarian_Advantages_Text}</p>
      <ul>
        <li>
            <strong>{strings.Blog_Hungarian_Advantage_Compatibility}</strong>
            {strings.Blog_Hungarian_Advantage_Compatibility_Text}
        </li>
        <li>
            <strong>{strings.Blog_Hungarian_Advantage_Cost}</strong>
            {strings.Blog_Hungarian_Advantage_Cost_Text}
        </li>
        <li>
          <strong>{strings.Blog_Hungarian_Advantage_Innovation}</strong>
          {strings.Blog_Hungarian_Advantage_Innovation_Text}
        </li>
      </ul>

      <h2>{strings.Blog_Hungarian_Impact_Title}</h2>
      <p>{strings.Blog_Hungarian_Impact_Text}</p>

      <h2>{strings.Blog_Hungarian_International_Title}</h2>
      <p>{strings.Blog_Hungarian_International_Text}</p>

      <h2>{strings.Blog_Hungarian_Summary_Title}</h2>
      <p>{strings.Blog_Hungarian_Summary_Text}</p>
        {strings.Blog_Author}
      <div className="author_div">
        <img className="author-image" src={iv} alt="CEO OrbitalChamp" />
        <div className="author-text"> 
          <p>
            <strong>{strings.Blog_Hungarian_Author_Name}</strong> <br />
            <hr className="rounded" />
            <span className="author-title">{strings.Blog_Hungarian_Author_Title}</span>
          </p>
        </div>
      </div>
    </div>

      <div className='empty-space'></div>
    </div>
  );
}else{
  return (
    <div className="blog-container">
   <div className='empty-space'></div>

   <div className="blog-content">
      <h1 className="bela-mobile">{strings.Blog_Hungarian_Title}</h1>
      <img
        className="welding-imagetop"
        src={hegesztobacsi}
        alt="Orbitális hegesztő munka közben"
      />
      <p>{strings.Blog_Hungarian_Paragraph1}</p>

      <h2>{strings.Blog_Hungarian_WhatIs_Title}</h2>
      <p>{strings.Blog_Hungarian_WhatIs_Text}</p>

      <h2>{strings.Blog_Hungarian_Advantages_Title}</h2>
      <p>{strings.Blog_Hungarian_Advantages_Text}</p>
      <ul>
        <li>
            <strong>{strings.Blog_Hungarian_Advantage_Compatibility}</strong>
            {strings.Blog_Hungarian_Advantage_Compatibility_Text}
        </li>
        <li>
            <strong>{strings.Blog_Hungarian_Advantage_Cost}</strong>
            {strings.Blog_Hungarian_Advantage_Cost_Text}
        </li>
        <li>
          <strong>{strings.Blog_Hungarian_Advantage_Innovation}</strong>
          {strings.Blog_Hungarian_Advantage_Innovation_Text}
        </li>
      </ul>

      <h2>{strings.Blog_Hungarian_Impact_Title}</h2>
      <p>{strings.Blog_Hungarian_Impact_Text}</p>

      <h2>{strings.Blog_Hungarian_International_Title}</h2>
      <p>{strings.Blog_Hungarian_International_Text}</p>

      <h2>{strings.Blog_Hungarian_Summary_Title}</h2>
      <p>{strings.Blog_Hungarian_Summary_Text}</p>
        {strings.Blog_Author}
      <div className="author_div">
        <img className="author-image" src={iv} alt="CEO OrbitalChamp" />
        <div className="author-text"> 
          <p>
            <strong>{strings.Blog_Hungarian_Author_Name}</strong> <br />
            <hr className="rounded" />
            <span className="author-title">{strings.Blog_Hungarian_Author_Title}</span>
          </p>
        </div>
      </div>
    </div>
    <div className="menu">
        <ul>
          <li><a href="/shop" replace>{strings.Webshop}</a></li>
          <li><a href="/Top3OkaHogyMiertHasznaljOrbitalisHegesztot"                 >{strings.Blog_Top3_Title}       </a></li>
          <li><a href="/OrbitalisHegesztesTortelnelme"                              >{strings.Blog_History_Title}    </a></li>
          <li><a href="/EzertLeszelEgyreErtekesebbMintHegeszto"                     >{strings.Blog_Valuable_Title}   </a></li>
          <li><a className="a_active" href="/AMagyarOrbitalisHegesztoAzOrbitalChamp">{strings.Blog_Hungarian_Title}  </a></li>
          <li><a href="/MiertNemVesziElAHegesztokMunkajatAzOrbitalisHegesztes"      >{strings.Blog_NoJobsTaken_Title}</a></li>
        </ul>
      </div>

    </div>
  );
}
}

export default BlogPostHunWeld;
