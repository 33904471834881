import React from 'react';

export default function scrollToSection(sectionId) {
    const section = document.getElementById(sectionId);
    if (section) {
        // Scroll to the section first
       section.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
        section.focus();
        section.scrollTo(0,800);
        // Apply the -200px offset after the initial scroll
    }
}
