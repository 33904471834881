import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import translations from '../../strings/Strings'
import { useHistory } from 'react-router-dom'; // Import useHistory
import next from '../../img/next-square.png';
import axios from 'axios';
import weldhead from '../../img/ShopImages/9.png'
import '../../Designe/ShopItems/ShopItemSmartWeldingSystem.css'
import '../../Designe/ShopItems/ShopItemSmartWeldingSystemMobile.css'
import Insert from '../../img/ShopImages/Insert.png'
import hegfej from '../../img/ShopImages/10.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHomeLgAlt } from "@fortawesome/free-solid-svg-icons";
import sizepic from '../../img/ShopImages/DIN50-size.png'


import kep3 from '../../img/new photo/3.png'
import ios from '../../img/ios_download.png'
import android from '../../img/android_download.png'
import kamuusermanual from '../../img/ShopImages/UserManual.pdf'
import { setLanguage } from '../../redux/actions';
import logo from '../../img/logo_transparent_edited.png'

import CEA from '../../img/WeldingMachiones/CEA.png'
import EWM from '../../img/WeldingMachiones/EWM.png'
import iWELD from '../../img/WeldingMachiones/iWeld_front.png'
import Kempi from '../../img/WeldingMachiones/Kempi_front.png'
import Lincoln from '../../img/WeldingMachiones/Lincoln_front.png'
import Lorch from '../../img/WeldingMachiones/Lorch_front.png'
import Merkle from '../../img/WeldingMachiones/Merkle_front.png'
import Miga from '../../img/WeldingMachiones/Miga_front.png'
import Miller from '../../img/WeldingMachiones/Miller_front.png'
import Rehm from '../../img/WeldingMachiones/Rehm.png'
import Syrius from '../../img/WeldingMachiones/Syrius.png'
import WECO from '../../img/WeldingMachiones/WECO.png'

function ShopItemWelderheadDIN50() {

    const language = useSelector((state) => state.language);
    const [strings,setStrings] = useState(translations.hun)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 762);
    const [selectedDevice, setSelectedDevice] = useState("CEA");
    const history = useHistory(); // 
    const images = [
        weldhead,
        Insert,  
        hegfej,
    ];

    const indexText = [
        strings.Shop_Item_IndexOrbitalWelderHead ,
        strings.Shop_Item_IndexBetet ,
        strings.Shop_Item_IndexWelderHeadEnd ,
    ];

    // TODO
    const altText = [
        strings.Shop_Item_IndexOrbitalWelderHead ,
        strings.Shop_Item_IndexBetet ,
        strings.Shop_Item_IndexWelderHeadEnd ,
    ];

    const maxIndex = images.length;

    const deviceImages = {
        "CEA": CEA,
        "EWM": EWM,
        "iWeld": iWELD,
        "Kempi": Kempi,
        "Lincoln": Lincoln,
        "Lorch": Lorch,
        "Merkle": Merkle,
        "Migatronic" : Miga,
        "Miller" : Miller,
        "Rehm" : Rehm, 
        "Syrius" : Syrius,
        "WECO" : WECO
      };

    useEffect(() => {
      // Itt lehet kezelni a nyelv állapotváltozásait
      console.log('Nyelv megváltozott:', language);
      switch (language) {
        case 'en':       
          setStrings(translations.en)
          break;  
        case 'hun':        
          setStrings(translations.hun)
          break;       
        case 'de':        
            setStrings(translations.de)
            break;         
        default:
          break;
      }
    }, [language]);

    
// Save language to local storage when it changes
const dispatch = useDispatch();
useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'hun'; // Default to Hungarian
    dispatch(setLanguage(savedLanguage)); // Set language in Redux
    setStrings(translations[savedLanguage])
}, [dispatch]);


    const [buttonText, setButtonText] = useState(strings.Shop_Item_Back);
    const tick = '✅';

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [email2, setEmail2] = useState('');
    const [company, setCompany] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [feedback, setFeedback] = useState({ message: '', type: '' });
    const [feedback2, setFeedback2] = useState({ message: '', type: '' });
    const [offer, setOffer] = useState('');
    const [loading, setLoading] = useState(false); // Loading state

    const offerRef = useRef(null);





    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 762);
    
        };
    
        
    
        if (isMobile) {
          setButtonText("⬅"); // Use dynamic text from strings or fallback to Hungarian
        }else {
            setButtonText(strings.Shop_Item_Back); // Use dynamic text for desktop
        }
        
        
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      });

      const [currentIndex, setCurrentIndex] = useState(0);
      const [isLightboxOpen, setIsLightboxOpen] = useState(false);
      const [lightboxImage, setLightboxImage] = useState('');

const handlePrevClick = () => {
    if (currentIndex > 0) {
        setCurrentIndex((prevIndex) => prevIndex - 1);
    }
};

const handleNextClick = () => {
    const maxIndex = images.length - (isMobile ? 2 : 3);
    if (currentIndex < maxIndex) {
        setCurrentIndex((prevIndex) => prevIndex + 1);
    }
};



    const handleImageClick = (image) => {
        setLightboxImage(image);
        setIsLightboxOpen(true);
    };




    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true); 
        if(!validateEmail(email))
        {   
            setFeedback2({message: strings.Shop_Item_Bad_Email, type: 'error'});

        }
        else if(!validatePhoneNumber(phoneNumber)){
             setFeedback({message: strings.Shop_Item_Bad_Number, type: 'error'})
        }
        else
        {
            var subj = offer==="offer" ? 'Ajánlatkérés a teljes szettre!' : 'Kapcsolatfelvétel az Orbitalchamp.com-ról!'
            axios
                .post('https://app-ppd7fma2sq-uc.a.run.app/sendmail', {
                    to: 'info@orbitalchamp.com', // Cél email cím
                    subject: subj,
                    message: `
                        Név: ${name}
                        Email: ${email}
                        Cégnév: ${company}
                        Telefonszám: ${phoneNumber}
                        Kiválasztott gép: ${selectedDevice}
                    `,
                })
                .then((response) => {
                    console.log('Sikeres email küldés:', response.data.message);
                    setFeedback({ message: strings.Shop_Item_Successful_Sending, type: 'success' });

                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Hiba az email küldésekor:', error);
                    setFeedback({ message: strings.Shop_Item_Unsuccesful_Sending, type: 'error' });

            setLoading(false);

                });
                
        }
        
    };

    function handleDownload() {
        // Create a link element
        const link = document.createElement('a');
        // Set the download attribute with the file name
        link.href = kamuusermanual; // Replace with the actual file path
        link.download = 'orbitalchamp-user-manual.pdf'; // The file name for the download
        link.click(); // Programmatically click the link to trigger the download
      }

    const handleSubmitUserManual = (e) => {
        e.preventDefault();
        
        if(!validateEmail(email2))
        {
            setFeedback2({message: strings.Shop_Item_Bad_Email, type: 'error'});
        }
        else
        {
            setLoading(true);
            axios
                .post('https://app-ppd7fma2sq-uc.a.run.app/sendmail', {
                    to: 'info@orbitalchamp.com', // Cél email cím
                    subject: 'Letöltötték a user manualt!',
                    message: `
                        Email: ${email2}

                    `,
                })
                .then((response) => {
                    console.log('Sikeres email küldés:', response.data.message);
                    setFeedback2({ message: strings.Shop_Item_Successful_Sending, type: 'success' });
            setLoading(false);
            handleDownload();

                })
                .catch((error) => {
                    console.error('Hiba az email küldésekor:', error);
                    setFeedback2({ message: strings.Shop_Item_Unsuccesful_Sending, type: 'error' });
            setLoading(false);

                });
        }
    };

    const handleOfferClick = () => {
        setOffer("offer");
    }

    
    const handleContactClick = () => {
        setOffer("contact");
    }
    
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email pattern
        return emailRegex.test(email);
    };
    
    const validatePhoneNumber = (number) => {
        const phoneRegex = /^\+?[0-9]{10,15}$/; // Allows optional "+" at the start and 10-15 digits
        return phoneRegex.test(number);
      };


      return (
        <div className="shop-main-container-sws">
            {isMobile ? 
              <a href="/shop" className={`button-back-sws`} >
                <FontAwesomeIcon icon={faHomeLgAlt} style={{ fontSize: "20px" }} color="#960000" />
              </a>
             :
              <a href="/shop" className={`button-back-sws`} >{buttonText}</a>

            }
               <p className='fake-header-sws'>{strings.Shop_OurProduct}</p> 
            <div className="shop-item-container-sws">
    {/* Text és kép része*/ }
                {isMobile ? 
               <div className='main-info'> 
                    {/* Mobile-specific content here */}
                    <h2 className='title-mobile'>{strings.Shop_WelderheadTitleDIN50}</h2>
                    <div className='img-container-sws'>
                        <img src={weldhead} alt="OrbitalChamp welder head" />
                    </div>
                    
                    <div className='text-container-sws'>
                        <p>{strings.Shop_Item_DIN50Info}</p>
                        <h3>{strings.Shop_Item_Contains}</h3>
                        <ul>
                            <li>{strings.Shop_Item_OrbitalWelder}</li>
                            <li>{strings.Shop_Item_Inlay}</li>
                            <li>{strings.Shop_Item_WelderHead}</li>
                        </ul>
                    </div>
                    <h1 className='price_a-sws'>{strings.Shop_Price}: 4449 € + {strings.VAT} </h1>
                </div>
           :
           <>
           {/* Desktop content here */}
               <div className='img-container-sws'>
                   <img src={weldhead} alt="OrbitalChamp welding system" />
           {/* BASZIK MŰKÖDNI
                   <button className='scrollToOffer-sws-button' onClick={handleScrollToOffer}>{strings.Shop_Item_Offer}</button>           
            */}
                   <h1 className='price_a-sws'>{strings.Shop_Price}: 4449 € + {strings.VAT} </h1>
               </div>
              
               <div className='text-container-sws'>
                   <h2>{strings.Shop_WelderheadTitleDIN50}</h2>
                   <p>{strings.Shop_Item_DIN50Info}</p>
                   <h3>{strings.Shop_Item_Contains}</h3>
                   <ul>
                       <li>{strings.Shop_Item_OrbitalWelder}</li>
                       <li>{strings.Shop_Item_Inlay}</li>
                       <li>{strings.Shop_Item_WelderHead}</li>
                   </ul>
               </div>
           </>
                }   
                
    {/* Gallery rész*/ }
                <div className="gallery-container-sws">
                <h2 className="gallery-title-sws">{strings.Shop_Item_Orbital_Products}</h2> 
                    <button className="prev-btn-sws" onClick={handlePrevClick}>
                        <img src={next} alt="Előzőkép a csőhegesztésről" />
                    </button>
                    <div className="gallery-grid-sws">
                        
                        {
                            images.slice(currentIndex, currentIndex + (isMobile ? 2 : 3)).map((image, index) => (
                                <div key={index} className="image-container-sws">
                                    <img
                                        src={image}
                                        alt={altText[index]}
                                        className="gallery-image-sws"
                                        onClick={() => handleImageClick(image)}
                                    />
                                    {/* Display corresponding text from indexText */}
                                    <p className="image-description-sws">
                                        {indexText[currentIndex + index]} {/* Adjust index to match with sliced images */}
                                    </p>
                                </div>
                            ))
                        }
                    </div>

                    <button className="next-btn-sws" onClick={handleNextClick}>
                        <img src={next} alt="Következő kép a csőhegesztésről" />
                    </button>
                </div>
    {/* Előnyök*/ }
                <div className='benefits-sws'>
                    <div className="benefit-list">
                        <h2>{strings.Shop_Item_Pro}</h2>
                        <ul className='ul-benefit'>
                            <li>{tick} {strings.Shop_Item_SWS_Vorteil1}</li>
                            <li>{tick} {strings.Shop_Item_SWS_Vorteil2}</li>
                            <li>{tick} {strings.Shop_Item_SWS_Vorteil3}</li>
                            <li>{tick} {strings.Shop_Item_SWS_Vorteil4}</li>
                            <li>{tick} {strings.Shop_Item_SWS_Vorteil5}</li>
                            <li>{tick} {strings.Shop_Item_SWS_Vorteil6}</li>
                        </ul>
                    </div>


                </div>
                
                <div className="size-range-container-din50">
                        <h2>{strings.Shop_Item_Sizes}</h2>
                    <div className="size-range-list-50-din50">
                        <h3>DIN50</h3>
                        <h4>{strings.Shop_Item_SizeOfWelderHead}</h4>
                        <ul className='mukodj'>             
                            <li>{strings.Shop_Item_Size_WidthDIN50}</li>
                            <li>{strings.Shop_Item_Size_HeightDIN50}</li>
                            <li>{strings.Shop_Item_Size_LengthDIN50}</li>
                            <li>{strings.Shop_Item_WeldingRangeDIN50}</li>
                        </ul>
                    </div>
                    <img className='size-img' src={sizepic}></img>
                   
                </div>
    {/* DIN választás
               <img src={Insert} alt="Betét csőhegesztéshez" className='inset-img' />
                <div className='inset-list'>
                    <h2>Betétek</h2>
                    <ul>
                            <li>{tick} Lorem ipsum dolor sit amet</li>
                            <li>{tick} Consectetur adipiscing elit</li>
                            <li>{tick} Sed do eiusmod tempor</li>
                            <li>{tick} Ut labore et dolore magna aliqua</li>
                            <li>{tick} Ut enim ad minim veniam</li>
                            <li>{tick} Quis nostrud exercitation ullamco</li>  
                        </ul>
                </div>
                        */ }
        {/* Eszköz kompatibilitás*/ }
                  
                {
                    isMobile ? 
                    <div className='comp-list'>
                    <h2>{strings.Shop_Item_Compatible_Devices} </h2>
                    <p>{strings.Shop_Item_Compatible_Devices_Info}</p>

                    <div className='two-column'>
                      {Object.keys(deviceImages).map((device) => (
                        <label key={device} className='radio-label'>
                          <input
                            type="radio"
                            name="device"
                            value={device}
                            checked={selectedDevice === device}
                            onChange={() => setSelectedDevice(device)}
                          />
                         {device}
                        </label>
                      ))}
                    </div>
                    <img 
                      src={selectedDevice ? deviceImages[selectedDevice] : kep3} 
                      alt="Device compatibility" 
                      className='device-img-comp' 
                    />
                  </div>
                :
                <>
                    <div className='comp-list'>
                        <h2>{strings.Shop_Item_Compatible_Devices} </h2>
                        <p>{strings.Shop_Item_Compatible_Devices_Info}</p>
                        <div className='two-column'>
                            {Object.keys(deviceImages).map((device) => (
                            <label key={device} className='radio-label'>
                                <input
                                type="radio"
                                name="device"
                                value={device}
                                checked={selectedDevice === device}
                                onChange={() => setSelectedDevice(device)}
                                />
                                {device}
                            </label>
                            ))}
                        </div>
                    </div>
                    <img
                        src={selectedDevice ? deviceImages[selectedDevice] : kep3}
                        alt="Device compatibility"
                        className='device-img-comp' 
                    />
                </>

                }
    {/* YT Video*/ }
                <div className="yt-video-container">
                    <h2>{strings.Shop_Item_Know_More}</h2>
                    <iframe 
                    className='yt-frame'
                    title='Bemutató videó'

                        src="https://www.youtube.com/embed/RAAmWiR-Gd8?si=apHbY-eqe1nmJkc8"
                        frameborder="0" 
                        allowfullscreen></iframe>
                    </div>
    {/* Ajánlatkérés meg vásárlásos gombok*/ }

                <div className="form-section-sws" ref={offerRef}>

                    <div className="form-container-sws">
                        <h2 className='form-container-sws-h2'>{strings.WeldingsAndForm_Form_Title}</h2>
                        <p className='form-container-sws-p'>{strings.WeldingsAndForm_Form_Address}</p>
                        <form className="form-sws" onSubmit={handleSubmit}>
                            <div className="form-row-sws">
                                <input
                                className='input-sws'
                                    type="text"
                                    placeholder={strings.Contact_name}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                                <input
                                className='input-sws'
                                    type="tel"
                                    placeholder={strings.Contact_Phone}
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-row-sws">
                                <input
                                className='input-sws'
                                    type="text"
                                    placeholder={strings.Contact_Company_name}
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                />
                                <input
                                className='input-sws'
                                    type="email"
                                    placeholder={strings.Contact_email}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <button onClick={handleContactClick} type="submit" aria-label="Jelentkezzen kapcsolat felvételre - Csőhegesztés és Orbitális hegesztés" >{strings.WeldingsAndForm_Form_Button_Demo}</button>
                            <p className='form-container-sws-p' >{strings.Application_Or}</p>
                            <button onClick={handleOfferClick}  type="submit" aria-label="Ajánlatkérés - Csőhegesztés és Orbitális hegesztés" >{strings.Shop_Item_Offer}</button>
                    
                        </form>
                        {loading && <div className="message-sending-animation"></div>} {/* Animation when loading */}
                        {feedback.message && (
                            <div className={`feedback_${feedback.type === 'success' ? 'success' : 'error'}`}>
                                <p>{feedback.message}</p>
                            </div>
                        )}
                    </div>       
                 </div>
    {/* App link*/ }
                <div className='app-link-sws'>
                    <h2>{strings.Shop_Item_Download_UM}</h2>
                    <form onSubmit={handleSubmitUserManual} className="email-download-form">
                  
                        <input
                          type="email" 
                          placeholder={strings.Shop_Item_Your_Email} 
                          value={email2} 
                          onChange={(e) => setEmail2(e.target.value)}
                          required />
                        <button type="submit" aria-label="Letöltés - Csőhegesztés és Orbitális hegesztés">{strings.Shop_Item_Download_Text}</button>
                        {loading && <div className="message-sending-animation"></div>} {/* Animation when loading */}
                        {feedback2.message && (
                            <div className={`feedback_${feedback2.type === 'success' ? 'success' : 'error'}`}>
                                <p>{feedback2.message}</p>
                            </div>
                        )}
                    </form>

                        <a href="https://play.google.com/store/apps/details?id=com.bendi.orbitalchamp" target="_blank" className="android-link">
                            <p>{strings.Shop_Item_Download_Android}</p>
                            <img src={android} alt="Android letöltés" />
                        </a>
             
                        <a href="https://apps.apple.com/hu/app/orbitalchamp/id6738270253?l=hu" target="_blank" className="ios-link">
                            <p>{strings.Shop_Item_Download_IOS}</p>
                            <img src={ios} alt="iOS letöltés" />
                        </a>
                
                </div>

                <img src ={logo} className='logo'></img> 
            </div>
        </div>
      );

}

export default ShopItemWelderheadDIN50;