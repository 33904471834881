import React, { useEffect, useState } from 'react';
import '../../Designe/Blogs/BlogPostHistory.css';
import hegesztobacsi from '../../img/history.jpg';
import iv from '../../img/t.png';
import { FacebookShareButton, FacebookIcon, FacebookShareCount} from 'react-share';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../../redux/actions';

import translations from '../../strings/Strings'

function BlogPostHistory() {

  const language = useSelector((state) => state.language);
  const [strings,setStrings] = useState(translations.hun)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 762);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 762);
    };

    
    // Itt lehet kezelni a nyelv állapotváltozásait
    console.log('Nyelv megváltozott:', language);
    switch (language) {
      case 'en':       
        setStrings(translations.en)
        break;  
      case 'hun':        
        setStrings(translations.hun)
        break;       
      case 'de':        
          setStrings(translations.de)
          break;         
      default:
        break;
    }
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [language]);

  var url = 'https://orbitalchamp.com/OrbitalisHegesztesTortelnelme';
  var title = strings.Blog_Top3_Title_Meta;
  var description = strings.Blog_Top3_Description_Meta;
  var imageUrl = hegesztobacsi;
  
  useEffect(() => {
    // Set the document title
    document.title = title;

    // Update meta tags dynamically
    const metaTags = [
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:image', content: imageUrl },
      { property: 'og:url', content: url },
      { property: 'og:type', content: 'article' },
      { property: 'og:site_name', content: 'OrbitalChamp.com' }
    ];

    metaTags.forEach(tag => {
      let element = document.querySelector(`meta[property='${tag.property}']`);
      
      if (!element) {
        // If the meta tag doesn't exist, create it
        element = document.createElement('meta');
        element.setAttribute('property', tag.property);
        document.head.appendChild(element);
      }

      element.setAttribute('content', tag.content);
    });
  }, [title, description, imageUrl, url]);  
  

// Save language to local storage when it changes
const dispatch = useDispatch();
useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'hun'; // Default to Hungarian
    dispatch(setLanguage(savedLanguage)); // Set language in Redux
    setStrings(translations[savedLanguage])
}, [dispatch]);

  


if(isMobile==false){
  return (

    <div className="blog-container">

    <Helmet>
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={imageUrl} />
          <meta property="og:url" content={url} />
          <meta property="og:type" content="article" />
          <meta property="og:site_name" content="OrbitalChamp.com" />
        </Helmet>

      <div className="menu">
        <ul>
          <li><a href="/shop" replace>{strings.Webshop}</a></li>
          <li><a href="/Top3OkaHogyMiertHasznaljOrbitalisHegesztot"           >{strings.Blog_Top3_Title}       </a></li>
          <li><a className="a_active" href="/OrbitalisHegesztesTortelnelme"   >{strings.Blog_History_Title}    </a></li>
          <li><a href="/EzertLeszelEgyreErtekesebbMintHegeszto"               >{strings.Blog_Valuable_Title}   </a></li>
          <li><a href="/AMagyarOrbitalisHegesztoAzOrbitalChamp"               >{strings.Blog_Hungarian_Title}  </a></li>
          <li><a href="/MiertNemVesziElAHegesztokMunkajatAzOrbitalisHegesztes">{strings.Blog_NoJobsTaken_Title}</a></li>
        </ul>
      </div>

      <div className="blog-content">
      <h1 className="bela">{strings.Blog_History_Title}</h1>
      <figure>
        <img
          className="welding-image_his"
          src={hegesztobacsi}
          alt="Orbitális hegesztő munka közben"
        />
        <figcaption className="fig_comment">{strings.Blog_Illustracio}</figcaption>
      </figure>
      <p>{strings.Blog_History_Paragraph1}</p>

      <h2>{strings.Blog_History_TheBeginnings_Title}</h2>
      <p>{strings.Blog_History_TheBeginnings_Text}</p>

      <h2>{strings.Blog_History_Technology_Title}</h2>
      <p>{strings.Blog_History_Technology_Text}</p>

      <h2>{strings.Blog_History_Heads_Title}</h2>
      <p>{strings.Blog_History_TwoTypes}</p>
      <ul>
        <li><strong>{strings.Blog_History_OpenHeads}</strong>{strings.Blog_History_OpenHeads_Text}</li>
        <li><strong>{strings.Blog_History_ClosedHeads}</strong>{strings.Blog_History_OpenHeads_Text}</li>
      </ul>

      <h2>{strings.Blog_History_Modern_Title}</h2>
      <p>{strings.Blog_History_Modern_Text}</p>

      <h2>{strings.Blog_History_Importance_Title}</h2>
      <p>{strings.Blog_History_Importance_Text}</p>

      <h2>{strings.Blog_History_Summary_Title}</h2>
      <p>{strings.Blog_History_Summary_Text}</p>
      {strings.Blog_Author}
      <div className="author_div">
        <img className="author-image" src={iv} alt="CEO OrbitalChamp" />
        <div className="author-text">
          <p>
            <strong>{strings.Blog_Author_Name}</strong> <br />
            <hr className="rounded" />
            <span className="author-title">{strings.Blog_Author_Title}</span>
          </p>
        </div>
      </div>
    </div>

      <div className="empty-space"></div>
    </div>
  );
}else{
  return (
    
    <div className="blog-container">
    <Helmet>
        <meta property="og:title" content="Orbitális hegesztés történelme" />
        <meta property="og:description" content="Jakab" />
        <meta property="og:image" content="https://orbitalchamp.com/static/media/history.1049c15f55c5f835378b.jpg" />
        <meta property="og:url" content="https://orbitalchamp.com/Top3OkaHogyMiertHasznaljOrbitalisHegesztot;" />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="OrbitalChamp.com" />
      </Helmet>
      <div className="empty-space"></div>

      <div className="blog-content">
      <h1 className="bela-mobile">{strings.Blog_History_Title}</h1>
      <figure>
        <img
          className="welding-image_his"
          src={hegesztobacsi}
          alt="Orbitális hegesztő munka közben"
        />
        <figcaption className="fig_comment">A kép illusztráció</figcaption>
      </figure>
      <p>{strings.Blog_History_Paragraph1}</p>

      <h2>{strings.Blog_History_TheBeginnings_Title}</h2>
      <p>{strings.Blog_History_TheBeginnings_Text}</p>

      <h2>{strings.Blog_History_Technology_Title}</h2>
      <p>{strings.Blog_History_Technology_Text}</p>

      <h2>{strings.Blog_History_Heads_Title}</h2>
      <p>{strings.Blog_History_TwoTypes}</p>
      <ul>
        <li><strong>{strings.Blog_History_OpenHeads}</strong>{strings.Blog_History_OpenHeads_Text}</li>
        <li><strong>{strings.Blog_History_ClosedHeads}</strong>{strings.Blog_History_OpenHeads_Text}</li>
      </ul>

      <h2>{strings.Blog_History_Modern_Title}</h2>
      <p>{strings.Blog_History_Modern_Text}</p>

      <h2>{strings.Blog_History_Importance_Title}</h2>
      <p>{strings.Blog_History_Importance_Text}</p>

      <h2>{strings.Blog_History_Summary_Title}</h2>
      <p>{strings.Blog_History_Summary_Text}</p>
      {strings.Blog_Author}
      <div className="author_div">
        <img className="author-image" src={iv} alt="CEO OrbitalChamp" />
        <div className="author-text">
          <p>
            <strong>{strings.Blog_Author_Name}</strong> <br />
            <hr className="rounded" />
            <span className="author-title">{strings.Blog_Author_Title}</span>
          </p>
        </div>
      </div>
    </div>
      
    <div className="menu">
        <ul>
          <li><a href="/shop" replace>{strings.Webshop}</a></li>
          <li><a href="/Top3OkaHogyMiertHasznaljOrbitalisHegesztot"           >{strings.Blog_Top3_Title}       </a></li>
          <li><a className="a_active" href="/OrbitalisHegesztesTortelnelme"   >{strings.Blog_History_Title}    </a></li>
          <li><a href="/EzertLeszelEgyreErtekesebbMintHegeszto"               >{strings.Blog_Valuable_Title}   </a></li>
          <li><a href="/AMagyarOrbitalisHegesztoAzOrbitalChamp"               >{strings.Blog_Hungarian_Title}  </a></li>
          <li><a href="/MiertNemVesziElAHegesztokMunkajatAzOrbitalisHegesztes">{strings.Blog_NoJobsTaken_Title}</a></li>
        </ul>
      </div>
    </div>
  );




}
}



export default BlogPostHistory;
