import React, { useState, useEffect } from 'react';
import '../Designe/OrbitalWelding.css';

import Orbital from '../img/form2.jpg';
import Orbital2 from '../img/Form.jpg';
import O1 from '../img/OrbitalWelding/O1.jpg';
import O2 from '../img/OrbitalWelding/O2.jpg';
import O3 from '../img/OrbitalWelding/O3.jpg';
import O4 from '../img/OrbitalWelding/O4.jpg';
import O5 from '../img/OrbitalWelding/O5.jpg';

import { useSelector } from 'react-redux';

import translations from '../strings/Strings';

const WeldingSection = () => {
    const images = [O3, O4, O5, Orbital, Orbital2];

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [nextImageIndex, setNextImageIndex] = useState((currentImageIndex + 1) % images.length);
    const [fade, setFade] = useState(false);  // To control fade in/out

    useEffect(() => {
        const intervalId = setInterval(() => {
            setFade(false); // Start fading out the current image

            // After the fade-out completes (1s), change the image and start fading in the new one
            setTimeout(() => {
                setCurrentImageIndex(nextImageIndex);
                setNextImageIndex((nextImageIndex + 1) % images.length);
                setFade(true); // Start fading in the new image
            }, 1000); // Match the duration with the opacity transition time (1s)
        }, 3000); // Change every 3 seconds

        return () => clearInterval(intervalId);
    }, [images.length, nextImageIndex]);

    const language = useSelector((state) => state.language);
    const [strings, setStrings] = useState(translations.hun);

    useEffect(() => {
        switch (language) {
            case 'en':
                setStrings(translations.en);
                break;
            case 'hun':
                setStrings(translations.hun);
                break;
            case 'de':
                setStrings(translations.de);
                break;
            default:
                break;
        }
    }, [language]);

    if (window.innerWidth > 762) {
        return (
            <div className="welding-section" id="Orbitális Hegesztés">
                <div className="welding-text">
                    <h2 className="welding-text-h2">{strings.OrbitalWeldings_Title}</h2>
                    <div className="description-container">
                        <p className="description-container-p">{strings.OrbitalWeldings_P1}</p>
                        <p className="description-container-p">{strings.OrbitalWeldings_P2}</p>
                        <p className="description-container-p">{strings.OrbitalWeldings_P3}</p>
                    </div>
                </div>
                <div className="welding-image">
                    {/* Current Image (Fading Out) */}
                    <img
                        key={currentImageIndex}
                        src={images[currentImageIndex]}
                        alt="Orbitális hegesztés"
                        className={`image ${fade ? 'fade-in' : 'fade-out'}`}  // Toggle fade-in/fade-out
                    />
                    {/* Next Image (Fading In) */}
                    <img
                        key={nextImageIndex}
                        src={images[nextImageIndex]}
                        alt="Orbitális hegesztés"
                        className={`image ${fade ? 'fade-out' : 'fade-in'}`}  // Toggle fade-in/fade-out
                    />
                </div>
            </div>
        );
    } else {
        return (
            <div className="welding-section-mobile" id="Orbitális Hegesztés">
                <div className="welding-text-mobile">
                    <h2 className="welding-title-mobile">{strings.OrbitalWeldings_Title}</h2>
                    <p className="description-mobile">{strings.OrbitalWeldings_P1}</p>
                    <p className="description-mobile">{strings.OrbitalWeldings_P2}</p>
                </div>
            </div>
        );
    }
};

export default WeldingSection;
